import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { SessionStorageService } from '@mercer/shared/data-access-api-household-ng';
import { CookieManagerService } from '@mercer/shared/util-cookies-ng';

@Injectable({
  providedIn: 'root',
})
export class InitiateComponentGuard {
  constructor(
    private sessionStorageService: SessionStorageService,
    private cookieManagerService: CookieManagerService
  ) {}

  async canActivate(): Promise<void | UrlTree> {
    console.log('start-sessionid', this.cookieManagerService.getCookie('SessionId'));

    if (
      !this.sessionStorageService.get(this.sessionStorageService.ACCESS_TOKEN_KEY) ||
      !this.sessionStorageService.get(this.sessionStorageService.SESSION_ID) ||
      this.sessionStorageService.get(this.sessionStorageService.SESSION_ID) !==
        this.cookieManagerService.getCookie('SessionId')
    ) {
      this.sessionStorageService.save(
        this.sessionStorageService.ACCESS_TOKEN_KEY,
        this.cookieManagerService.getCookie(this.sessionStorageService.ACCESS_TOKEN_KEY)
      );
      this.sessionStorageService.save(
        this.sessionStorageService.SESSION_ID,
        this.cookieManagerService.getCookie('SessionId')
      );
      this.cookieManagerService.deleteCookie(this.sessionStorageService.ACCESS_TOKEN_KEY);
      this.cookieManagerService.deleteCookie(this.sessionStorageService.SESSION_ID);
    }
  }
}
